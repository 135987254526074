@tailwind base;
@tailwind components;
@tailwind utilities;

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 500px;
  text-align: center;
  padding-top: 5px;
}

/* .body {
  background-color: #fcda05;
} */

.steps-action {
  margin-top: 24px;
}

.avatar-uploader > .ant-upload {
  width: 228px;
  height: 228px;
}

.ant-upload-list-picture-card {
  display: initial;
}

/* Color picker */
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
.color-input {
  display: none;
}
.color-label {
  margin-left: 15px;
  position: absolute;
  height: 25px;
  width: 40px;
  border-radius: 3px;
  -moz-box-shadow: inset 0 0 4px #000000;
  -webkit-box-shadow: inset 0 0 4px #000000;
  box-shadow: inset 0 0 4px #000000;
  /*box-shadow: inset 0px 11px 8px -10px #CCC,  inset 0px -11px 8px -10px #CCC; */
}
.color-input:checked ~ .color-picker {
  opacity: 1;
}
.color-picker {
  position: absolute;
  left: -300%;
  background-color: white;
  height: 160px;
  width: 198px;
  border: solid 1px #ccc;
  padding: 5px;
  opacity: 1;
  transition: all 0.3s;
  z-index: 9;
}

.logo-color-picker > .color-picker-div > .color-picker {
  left: -290%;
}

.text-color-picker > .color-picker-div > .color-picker {
  left: -330%;
}

.fontawesome-color-picker > .color-picker-div > .color-picker {
  left: -300%;
}

canvas:hover {
  cursor: crosshair;
}
.color-strip {
  margin-left: 5px;
}

.top-nav-div {
  height: 2.5rem;
  background-color: #36a4ce;
  color: #fff;
}

.login-btn {
  margin-right: 1rem;
  line-height: 2rem;
  font-size: 1.2rem;
  cursor: pointer;
}
.add-item-btn {
  display: inline-block;
}
.add-item-btn button {
  color: #797979;
  background-color: #fff;
  border: solid #797979;
  border-width: 1px;
  width: 100px;
  height: 40px;
  font-size: 18px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
}
.add-item-btn button:hover {
  color: #fff;
  background-color: #36a4ce;
  border: solid #36a4ce;
}
.download-btn {
  color: #676565 !important;
  background-color: #fbff00 !important;
  border: solid #fbff00 !important;
  border-width: 1px;
  width: 200px !important;
  height: 40px;
  font-size: 18px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 400;
  float: right;
}

.hidden-input-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.btn-logo {
  position: relative;
}

.clearfix {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  display: flex;
  flex-direction: column;
}
.upload-clearall-div {
  min-height: 1rem;
  background-color: gray;
  margin-left: auto;
}

/*.canvas-container{
  /*margin: 20px 0px 20px 30px;*/
/*width: 1300px !important;
  display: inline-block;
  vertical-align: top;
}*/

/*.position-watermark > .canvas-container{
  margin: 20px 0px 20px 30px;
  width: 1300px !important;
  display: inline-block;
  vertical-align: top;
}*/
.ant-dropdown {
  left: 90% !important;
}
.properties_div {
  width: 300px;
  margin-top: 20px;
  display: inline-block;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.properties_heading {
  text-align: center;
  color: #656565;
}

/* h1,
h2,
h3,
h4,
h5,
h6 {
  color: #656565 !important;
} */

#offCanvas_div,
#offCanvas_div > .canvas-container,
#offCanvas_div canvas {
  height: 0px !important;
  width: 0px !important;
}
/*.canvas-container > .lower-canvas,
.canvas-container > .upper-canvas {
   width: 1300px !important; 
}*/
.btn_download_photo {
  width: 100%;
  background-color: #ffeb3b;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border: none;
  height: 30px;
  font-weight: bold;
  margin-top: 15px;
  cursor: pointer;
  /* color: #272727; */
}
.bg_orange {
  background-color: #ff5722 !important;
}
.bg_light_green {
  background-color: #69b231 !important;
}
.whitecolor {
  color: white;
}
.text_middle_line {
  width: 100%;
  text-align: center;
  border-bottom: 1px dotted #656565;
  line-height: 0.1em;
  margin-top: 20px;
}
.text_middle_line span {
  background: #fff;
  padding: 0 10px;
}

/*SignUp Modal*/
.btn_signin {
  color: #ffffff;
  background-color: #2980b9;
  width: 100%;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border: none;
  height: 30px;
  font-weight: bold;
  margin-top: 15px;
  cursor: pointer;
}
.btn_signin img {
  width: 15px;
}
.bg_google {
  color: #656565;
  background-color: #fff;
}
.bg_facebook {
  color: #ffffff;
  background-color: #3b5998;
}
.btn_createAccount {
  text-align: center;
  cursor: pointer;
}
.btn_createAccount:hover {
  color: #000;
}

.marginTop10 {
  margin-top: 10px !important;
}

#font-picker {
  box-shadow: none;
  border-color: lightgray;
  border-width: 2px;
  border-radius: 0.25rem;
}
#font-picker > button {
  background-color: white;
  box-shadow: none;
}
.unselectable {
  pointer-events: none;
}

.paidIndicator::after {
  content: '*';
  color: #ff4646;
  font-size: x-large;
  margin-top: 7px;
  margin-left: 3px;
  /*font-weight: bold;*/
}

.Dropdown-control {
  border-radius: 8px !important;
  background-color: #e5e7eb !important;
  border: none !important;
  font-size: large !important;
  font-weight: 700 !important;
  cursor: pointer !important;
}

.Dropdown-arrow {
  right: 12px !important;
  top: 16px !important;
  border-width: 10px 8px 0 !important;
  border-color: #908e8e transparent transparent !important;
}

.Dropdown-menu {
  background-color: #f3f4f6 !important;
  border: none !important;
  font-size: large;
}
.helpIcon {
  font-size: xx-large;
  border-radius: 20px;
  padding: 5px;
}
